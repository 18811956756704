<template>
    <div>
        <div v-if="show">
            <div class="card" v-show="operation !== 'detail'">
                <div class="card-header card-header-flex pb-2">
                    <div class="w-100 mt-2">
                        <div class="row">
                            <div class="col-8">
                                <h5 class="mt-3 ml-0 mr-3 mb-2">
                                    <strong>
                                        <template v-if="operation === null">{{$t('title.serviceOrders')}}</template>
                                        <template v-else>{{ $t(operationTitle) }}</template>
                                    </strong>
                                </h5>
                            </div>
                            <div class="col-4 text-right">
                                <div v-if="operation === null">
                                    <div class="mt-3">
                                        <!-- Using components -->
                                        <b-input-group class="mt-3">
                                            <b-form-input type="search" class="form-control form-control-sm"
                                                          :placeholder="$t('input.whatAreYouLookingFor')"
                                                          v-on:keyup.enter="handleSearch" v-model="search"></b-form-input>
                                            <b-input-group-append>
                                                <b-button size="sm" :title="$t('button.title.filterRecords')"
                                                          variant="outline-info"
                                                          @click="filters.visible = !filters.visible" v-b-tooltip.hover
                                                          v-if="$global.hasPermission('ordersownview')">
                                                    <i class="fa fa-filter"></i>
                                                </b-button>
                                                <b-button size="sm" :title="$t('button.title.resetList')"
                                                          variant="outline-info"
                                                          @click="handleResetClick()" v-b-tooltip.hover>
                                                    <i class="fa fa-refresh"></i>
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </div>
                                </div>
                                <div v-else>
                                    <b-button variant="warning" size="sm" class="mt-3"
                                              @click="handleOperationClose()"
                                              v-b-tooltip.hover :title="$t('button.title.cancel')">
                                        <i class="fa fa-arrow-left"></i> {{$t('button.cancel')}}
                                    </b-button>
                                </div>
                            </div>
                        </div><!-- /.row -->
                    </div><!-- /.w-100 -->
                </div><!-- /.card-header -->
                <div class="card-body">
                    <div class="orders-table">
                        <b-table hover responsive
                                 :busy="listingLoading"
                                 :items="dataSource"
                                 :fields="columns"
                                 :sort-by.sync="sortField"
                                 ref="orderTable"
                                 @sort-changed="handleSortChange">
                            <template v-slot:cell(order_id)="{ detailsShowing, item, index, rowSelected}">
                                <div class="position-relative">
                                    <template v-if="rowSelected">
                                        <span aria-hidden="true">&check;</span>
                                    </template>
                                    <a @click="toggleDetails(item)" class="d-inline in-center">
                                        <i :class="[{'fa fa-plus': !detailsShowing}, {'fa fa-minus': detailsShowing}]"></i>
                                    </a>
                                    <span class="indicator-added-inbound-load" v-if="item.load_status < 7 && item.added_in_load_at" :title="$t('title.inboundAdded')" v-b-tooltip.hover></span>
                                    <span class="indicator-added-outbound-load" v-if="item.load_status >= 10 && item.load_status < 17" :title="$t('title.outboundAdded')" v-b-tooltip.hover></span>
                                    {{ (item.order_id ? item.order_id : '') }}
                                </div>
                            </template>
                            <template v-slot:cell(status)="record">
                                {{ record.item._status }}
                            </template>
                            <template v-slot:cell(load_status)="record">
                                <ol-status :status="record.item.load_status"></ol-status>
                                <template v-if="record.item.client_affected"><br>
                                    <b-badge v-if="record.item.client_affected_priority === 1" variant="danger" :title="$t('title.clientAffected') + ' | ' + $t('title.high') + (record.item.client_affected_description ? ' | ' + record.item.client_affected_description : '')" v-b-tooltip.hover>{{$t('title.clientAffected')}} </b-badge>
                                    <b-badge v-if="record.item.client_affected_priority === 2" variant="warning" :title="$t('title.clientAffected') + ' | ' + $t('title.medium') + (record.item.client_affected_description ? ' | ' + record.item.client_affected_description : '')" v-b-tooltip.hover>{{$t('title.clientAffected')}}</b-badge>
                                    <b-badge v-if="record.item.client_affected_priority === 3" variant="secondary" :title="$t('title.clientAffected') + ' | ' + $t('title.low') + (record.item.client_affected_description ? ' | ' + record.item.client_affected_description : '')" v-b-tooltip.hover>{{$t('title.clientAffected')}}</b-badge>
                                </template>
                            </template>
                            <template v-slot:cell(brand_id)="record">
                                {{ (record.item.brand ? record.item.brand.title : '') }}
                                <div>{{ (record.item.model ? record.item.model.title : '') }}</div>
                            </template>
                            <template v-slot:cell(from_location_id)="{item}">
                                <span v-if="item.from_location">
                                    <span><img :src="'/resources/images/flags/' + (_.find(dropdowns.countries, {id: item.from_location.country}) ? _.find(dropdowns.countries, {id: item.from_location.country}).sort_name : '') + '.png'" class="mr-2"></span>
                                    <span v-if="item.from_location.city" :title="item.from_location.street_no + ', ' + item.from_location.street + ', ' + item.from_location.city + ', ' + item.from_location.zip + ', ' + item.from_location.country" v-b-tooltip.hover>{{item.from_location.city}} </span>
                                </span>
                            </template>
                            <template v-slot:cell(to_location_id)="{item}">
                                <span v-if="item.to_location">
                                    <span><img :src="'/resources/images/flags/' + (_.find(dropdowns.countries, {id: item.to_location.country}) ? _.find(dropdowns.countries, {id: item.to_location.country}).sort_name : '') + '.png'" class="mr-2"></span>
                                    <span v-if="item.to_location.city" :title="item.to_location.street_no + ', ' + item.to_location.street + ', ' + item.to_location.city + ', ' + item.to_location.zip + ', ' + item.to_location.country" v-b-tooltip.hover>{{item.to_location.city}} </span>
                                </span>
                            </template>
                            <template v-slot:row-details="{ item }">
                                <b-card>
                                    <b-list-group flush>
                                        <b-list-group-item><strong>{{$t('column.importerNumber')}}</strong>: {{item.invoice_number_importer}}</b-list-group-item>
                                        <b-list-group-item><strong>{{$t('column.comment')}}</strong>: {{item.comments}}</b-list-group-item>
                                    </b-list-group>
                                </b-card>
                            </template>
                            <template v-slot:cell(action)="record">
                                <a @click="handleEditClick(record.item)"
                                   v-if="record.item.load_status == 8 || record.item.load_status == 9"
                                   :title="$t('button.title.manageAdditionalServices')"
                                   class="ml-1" v-b-tooltip.hover>
                                    <i class="fe fe-file-plus"></i>
                                </a>
                                <a @click="setOperation('detail', record.item.id)"
                                   :title="$t('button.title.detailItem')"
                                   class="ml-1"
                                   v-b-tooltip.hover>
                                    <i class="icmn-info"></i>
                                </a>
                            </template>
                        </b-table><!-- /.b-table -->

                        <div class="clearfix">
                            <div class="float-left ">
                                <b-form-select v-model="pagination.perPage" :options="[5, 10, 50, 100, 1000]" size="sm"></b-form-select>
                            </div>
                            <div class="float-right">
                                <b-pagination
                                        v-model="pagination.current"
                                        :total-rows="pagination.total"
                                        :per-page="pagination.perPage"
                                        :first-text="$t('paginations.first')"
                                        :prev-text="$t('paginations.prev')"
                                        :next-text="$t('paginations.next')"
                                        :last-text="$t('paginations.last')"
                                ></b-pagination>
                            </div><!-- /.pull-right -->
                        </div><!-- /.clearfix -->
                    </div><!-- /.orders-table-->
                    <div class="orders-operation">
                        <a-drawer
                            placement="right"
                            :width="'80%'"
                            :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                            :closable="false"
                            @close="handleOperationClose"
                            :visible="!(!(formFields.id))"
                            :zIndex="10"
                            :title="$t(operationTitle)"
                        >
                            <form @submit.prevent="handleSubmit" autocomplete="off">
                                <b-row>
                                    <b-col sm="12">
                                        <b-row>
                                            <b-col sm="12">
                                                <table class="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th width="10">#</th>
                                                            <th width="250">
                                                                {{$t('column.additionalService')}} /
                                                                {{$t('column.description')}}
                                                            </th>
                                                            <th>{{$t('column.compoundNote')}}</th>
                                                            <th width="200">ETR</th>
                                                            <th width="150">Start work now:</th>
                                                            <th width="150">{{$t('input.isDone')}}</th>
                                                            <th width="150">{{$t('input.reject')}}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(ad, index) in additional_services">
                                                            <td>{{index + 1}}</td>
                                                            <td>
                                                                <div>{{ad.additional_service_name}}</div>
                                                                <div>{{ad.description}}</div>
                                                            </td>
                                                            <td>
                                                                <b-textarea
                                                                    v-if="!ad.edit_is_rejected"
                                                                v-model="ad.compound_notes"></b-textarea>
                                                            </td>
                                                            <td>
                                                                <b-form-group>
                                                                    <a-date-picker
                                                                        id="estimated_time_repair"
                                                                        :show-time="{ format: 'HH:mm' }"
                                                                        placeholder=""
                                                                        v-if="!ad.edit_is_rejected"
                                                                        format="DD-MM-YYYY hh:mm A"
                                                                        v-model="ad.estimated_time_repair"
                                                                    />
                                                                </b-form-group>
                                                            </td>
                                                            <td>
                                                                <b-form-checkbox switch
                                                                 v-model="ad.time_repair_start"
                                                                 v-if="!ad.edit_is_rejected"
                                                                 :label="$t('input.isDone')" size="lg">{{ad.time_repair_start ? $t('msc.yes') : $t('msc.no')}}</b-form-checkbox>
                                                            </td>
                                                            <td>
                                                                <b-form-checkbox switch
                                                                 v-model="ad.is_done"
                                                                 v-if="!ad.edit_is_rejected"
                                                                 size="lg">{{ad.is_done ? $t('msc.yes') : $t('msc.no')}}</b-form-checkbox>
                                                            </td>
                                                            <td>
                                                                <span v-if="ad.edit_is_rejected" class="badge badge-danger">{{$t('title.rejected')}}</span>
                                                                <b-form-checkbox switch
                                                                 v-model="ad.is_rejected"
                                                                v-if="!ad.edit_is_rejected"
                                                                 :disabled="ad.edit_is_rejected"
                                                                size="lg">{{ad.is_rejected ? $t('msc.yes') : $t('msc.no')}}</b-form-checkbox>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    <tfoot v-show="additional_services.length <= 0">
                                                        <tr>
                                                            <th colspan="8">{{$t('title.noDataAvailable')}}</th>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                    <div class="drawer-footer">
                                        <b-button variant="danger" class="ml-2 float-left"
                                                  size="sm" @click="resetAdditionalServices()"
                                                  v-b-tooltip.hover :title="$t('button.title.resetAdditionalServices')">
                                            <i class="fa fa-refresh mr-1"></i> {{$t('button.resetAdditionalServices')}}
                                        </b-button>
                                        <b-button
                                            size="sm"
                                            type="submit"
                                            variant="primary"
                                            :disabled="global.pendingRequests > 0"
                                            v-b-tooltip.hover :title="$t('button.title.save')"
                                        >
                                            <clip-loader style="display: inline" :loading="true" color="#fff"
                                                         size="12px"
                                                         v-if="global.pendingRequests > 0"></clip-loader>
                                            <i class="fa fa-save mr-1"></i>
                                            {{$t('button.save')}}
                                        </b-button>
                                        <b-button variant="warning" class="ml-3"
                                                  size="sm" @click="afterCloseOperation()"
                                                  v-b-tooltip.hover :title="$t('button.title.cancel')">
                                            <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                                        </b-button>
                                    </div>
                                </b-row><!--/b-row-->
                            </form><!--/form-->
                        </a-drawer>
                    </div><!--/.orders-operation-->
                    <div class="filter-container">
                        <a-drawer
                            placement="left"
                            :width="'360px'"
                            :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                            :closable="false"
                            @close="filters.visible = !filters.visible"
                            :visible="!operation && filters.visible"
                            :zIndex="10"
                            :title="$t('title.advanceFilters')"
                        >
                            <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
                                <b-row>
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.brands')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.brands"
                                                placeholder=""
                                                v-model="filters.brands"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.models')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.models"
                                                placeholder=""
                                                v-model="filters.models"
                                                @input="handleSelectModelsSelect"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.code')">
                                            <treeselect
                                                :multiple="true"
                                                :options=" _.uniqBy(_.filter(dropdowns.configCode, ({ model_id }) => _.every([_.includes(filters.models, model_id)])),'id')"
                                                placeholder=""
                                                v-model="filters.configCode"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.loadingPoint')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.fromLocations"
                                                placeholder=""
                                                v-model="filters.fromLocations"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.unloadingPoint')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.toLocation"
                                                placeholder=""
                                                v-model="filters.toLocation"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.drivers')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.drivers"
                                                placeholder=""
                                                v-model="filters.drivers"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.vehicles')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.vehicles"
                                                placeholder=""
                                                v-model="filters.vehicles"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group :label="$t('input.fromAddedDate')">
                                            <b-form-datepicker placeholder="" v-model="filters.from_date"
                                                               class="mb-2"></b-form-datepicker>
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group :label="$t('input.toAddedDate')">
                                            <b-form-datepicker placeholder="" v-model="filters.to_date"
                                                               class="mb-2"></b-form-datepicker>
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                </b-row>
                                <div class="drawer-footer">
                                    <b-button size='sm' variant="info" @click="filters.visible = !filters.visible"
                                              class="mr-2" :title="$t('button.title.closePanel')" v-b-tooltip.hover>
                                        {{$t('button.close')}}
                                    </b-button>
                                    <b-button size='sm' variant="warning" @click="handleResetFilterClick" class="mr-2"
                                              :title="$t('button.title.resetFilter')" v-b-tooltip.hover>
                                        {{$t('button.reset')}}
                                    </b-button>
                                    <b-button size='sm' variant="primary" button="submit" type="filled"
                                              :title="$t('button.title.filterRecords')" v-b-tooltip.hover>
                                        {{$t('button.apply')}}
                                    </b-button>
                                </div><!-- /.drawer-footer -->
                            </form>
                        </a-drawer>
                    </div><!-- /.filter-container -->
                </div><!-- /.card-body-->
            </div><!-- /.card -->
            <div v-if="operation === 'detail'">
                <detail :handle-close-operation="handleOperationClose"></detail>
            </div>
        </div>
    </div><!--/div-->
</template>
<script>
    import ListingMixin from '../../../../util/ListingMixin'
    import Error from '../../../../util/Error'
    import {mapState} from 'vuex'
    import {request} from '../../../../util/Request'
    import Treeselect from '@riophae/vue-treeselect'
    import Detail from '../detail'

    const FORM_STATE = {
        id: null,
        additional_services: [],
        _method: 'post',
    };

    const FILTER_STATE = {
        visible: false,
        from_date: null,
        to_date: null,
        brands: [],
        models: [],
        configCode: [],
        drivers: [],
        vehicles: [],
        fromLocations:[],
        toLocation:[],
    };

    const COLUMN_DEFINITION = (self) => [
        {
            label: self.$t('column.orderId'),
            key: 'order_id',
            sortable: true,
            stickyColumn: true,
        },
        {
            label: self.$t('column.vin'),
            key: 'vin_number',
            sortable: true,
        },
        {
            label: self.$t('column.loadingPoint'),
            key: 'from_location_id',
            sortable: true,
            class: 'w-220'
        },
        {
            label: self.$t('column.unLoadingPoint'),
            key: 'to_location_id',
            sortable: true,
            class: 'w-220'
        },
        {
            label: self.$t('column.brand')+'/'+self.$t('column.model'),
            key: 'brand_id',
            sortable: true,
        },
        {
            label: self.$t('column.status'),
            key: 'load_status',
            sortable: true,
        },
        {
            label: self.$t('column.action'),
            class: 'text-right',
            key: 'action',
            width: 150,
        }
    ];

    export default {
        mixins: [ListingMixin],
        components: {
            Treeselect,
            Detail
        },
        data() {
            return {
                operationTitle: 'title.orders',
                formFields: {...FORM_STATE},
                filters: {...FILTER_STATE},
                listUrl: 'orders/compound/supplier/services',
                columns: COLUMN_DEFINITION(this),
                show: true,
                additional_services: [],
                dropdowns: {
                    countries: [],
                    brands: [],
                    models: [],
                    configCode: [],
                    drivers: [],
                    vehicles: [],
                    fromLocations:[],
                    toLocation:[],
                },
            }
        },
        mounted() {
            this.$title = this.$t('title.serviceOrders')
            this.getCountries();
            this.getBrands();
            this.getModels();
            this.getDrivers();
            this.getTransportVehicles();
            this.getLocations();
        },
        methods: {
            toggleDetails(row) {
                this.$set(row, '_showDetails', ((row._showDetails) ? !row._showDetails : true))
            },
            setOperation(operation = 'add', operationToken = null) {
                this.operationTitle = (operation === 'add' ? 'title.addOrder' : 'title.editOrder')
                this.$router.replace({
                    query: Object.assign({},
                        this.$route.query,
                        {
                            ...this.listQueryParams,
                            operation: operation,
                            oToken: operationToken,
                        },
                    ),
                }).then(() => {
                }).catch(() => {
                })
            },
            async handleSubmit() {
                try {
                    const response = await request({
                        url: '/orders/compound/supplier/update/additional/service',
                        method: 'post',
                        data: {
                            ...this.formFields,
                            additional_services: this.additional_services.map((item) => ({
                                ...item,
                                is_rejected: (item.is_rejected === true ? 1 : 0),
                                is_done: (item.is_done === true ? 1 : 0),
                                time_repair_start: (item.time_repair_start === true ? 1 : 0),
                            })),
                        },
                    })
                    this.itemUpdated()
                    this.afterCloseOperation()
                    this.loadList()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                        this.validationError()
                        return false
                    }

                    this.handleServerError(error)
                }
            },
            async handleEditClick({additional_services, id}) {
                this.operationTitle = this.$t('title.updateServiceOrders')
                this.additional_services = (additional_services) ? additional_services.map((item) => ({
                    id: item.token,
                    is_old: item.is_old,
                    token: item.token,
                    additional_service_name: item.additional_service.title,
                    description: item.description,
                    estimated_time_repair: item.estimated_time_repair,
                    compound_notes: item.compound_notes,
                    is_done: (!(!item.marked_done_at)),
                    is_rejected: (!(!item.is_rejected)),
                    edit_is_rejected: (!(!item.is_rejected)),
                    edit_estimated_time_repair: (!(!item.estimated_time_repair)),
                    time_repair_start: (!(!item.time_repair_start)),
                    error: false
                })) : []

                this.formFields.id = id
            },
            async resetAdditionalServices() {
                try {
                    const response = await request({
                        url: '/orders/compound/supplier/services/delete',
                        method: 'post',
                        data: { id: this.formFields.id },
                    })
                    this.itemUpdated()
                    this.afterCloseOperation()
                    this.loadList()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                        this.validationError()
                        return false
                    }

                    this.handleServerError(error)
                }
            },
            async getBrands() {
                try {
                    const response = await request({
                        url: '/dropdowns/brands',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.brands = data

                } catch (e) {
                    this.dropdowns.brands = []
                }
            },
            async getModels() {
                try {
                    const response = await request({
                        url: '/dropdowns/models',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.models = data.map(item => ({id: item.id, label: `${item.label}`}))
                    this.dropdowns.configCode = data.map(item => ({
                        id: item.version_code,
                        label: item.version_code,
                        model_id: item.id
                    }))
                } catch (e) {
                    this.dropdowns.models = []
                    this.dropdowns.configCode = []
                }
            },
            async getDrivers() {
                try {
                    const response = await request({
                        url: '/dropdowns/drivers',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.drivers = data
                } catch (e) {
                    this.dropdowns.drivers = []
                }
            },
            async getTransportVehicles() {
                try {
                    const response = await request({
                        url: '/dropdowns/transport/vehicles',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.vehicles = data
                } catch (e) {
                    this.dropdowns.vehicles = []
                }
            },
            async getLocations() {
                try {
                    const response = await request({
                        url: '/dropdowns/locations',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.fromLocations = data
                    this.dropdowns.toLocation = data
                } catch (e) {
                    this.dropdowns.fromLocations = []
                    this.dropdowns.toLocation = []
                }
            },
            async getCountries() {
                try {
                    const response = await request({
                        url: '/dropdowns/countries',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.countries = data

                } catch (e) {
                    this.dropdowns.countries = []
                }
            },
            handleSelectModelsSelect(changeValue) {
                this.filters.configCode = []
            },
            hasListAccess() {
                return this.$global.hasPermission('ordersownview')
            },
            refreshList() {
                this.loadList()
            },
            getExtraParams() {
                return {
                    filters: {
                        ...this.filters,
                        from_date: ((this.filters.from_date) ? this.$global.dateToUtcDate(this.filters.from_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                        to_date: ((this.filters.to_date) ? this.$global.dateToUtcDate(this.filters.to_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                    },
                }
            },
            handleResetFilterClick() {
                this.filters = {...FILTER_STATE}
                this.isFilterApplied = 'reset'
                this.loadList(this.listQueryParams)
            },
            afterCloseOperation() {
                this.formFields = {...FORM_STATE}
            },
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }
</script>
<style lang="scss">
    .w-80{
        width: 80px;
    }

    .w-220{
        width: 220px;
    }

    .in-center{
        position: relative;
        top: 1px;
        margin-right: 8px;
    }

    @media screen and (max-width: 1379px) {
        .ant-drawer-content-wrapper {
            width: 98% !important;
        }
    }

    .indicator-added-inbound-load{
        bottom: -4px;
        left: 0;
    }

    .indicator-added-outbound-load{
        bottom: -4px;
        left: 15px;
    }
    .indicator-added-priority {
        bottom: -13px;
        left: 0;
    }
</style>
